

import React from 'react';
import { RouterProvider } from 'react-router-dom';
import routes from './Routes';
import NavBar from './components/NavBar';

export default function App() {
  return (
    <div className="App">
    <NavBar />
    <RouterProvider router={routes} />
  </div>
  )
}