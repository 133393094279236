import React, { useEffect, useState } from "react";
import axios from "axios";
import "../App.css";
import { Modal } from "antd";
import questionIcon from "../assets/image/question-mark.svg"
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import { Link } from "react-router-dom";

function HomePage() {
  const token = "09eb401e8b72dc68a4fca80872896456157d828d";
  const [tc, setTc] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const apiUrl = `https://sbs.tvgfbf.gov.tr/api/coach/?tc=${tc}`;

  const handleQuery = () => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (response?.data?.status === "Success") {
          window.location.href = `/visa/${tc}`;
          // Clear the input field after a successful query
          setTc("");
        } else {
          alert("Lütfen geçerli bir Tc kimlik numarası giriniz");
        }
      } catch (error) {
        console.error("API isteği sırasında hata oluştu:", error);
      }
    };

    fetchData();
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="App">
      <div className="search-side">
        <div className="input-section">
          <div className="search-input">
            <Input
              description={"T.C. Kimlik Numarası"}
              placeholder={"Lütfen T.C. kimlik numaranızı girin"}
              value={tc} // Set the input value to the 'tc' state
              onChange={(e) => setTc(e.target.value)}
              padding={"15px 0px 15px 5px"}
            />
          </div>
          <div className="home-button-section">
            <Button text={"Sorgula"} backgroundColor={"black"} width={"90%"} onClick={handleQuery} />
            <Link style={{ width: "100%", display: "flex", justifyContent: "center" }} to={"https://tvgfbf.gov.tr/"}>
              <Button text={"TVGFBF Resmi Websitesi"} backgroundColor={"#E3BC2F"} width={"90%"} />
            </Link>
            <Button text={"Vize Sorgulama Sistemi Nedir ?"} backgroundColor={"#2FADE3"} width={"90%"} onClick={showModal} />
          </div>
        </div>
      </div>
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="question-modal-container">
          <img src={questionIcon} alt="question" />
          <h2>Antrenör Vize Sorgulama Sistemi Nedir?</h2>
          <p>Türkiye Vücut Geliştirme Ve Bilek Güreşi Federasyonu tarafından, antrenörlerin vize tarihlerini takip edebilmeleri, belge durumları ve vize durumlarını anında kontrol edebilmeleri için hazırlanmış otomatik bir yazılımdır.</p>
          <p>Bu sayfada ki tüm veriler, Spor Bilgi Sistemi (SBS) üzerinden alınmaktadır. Her hangi bir hata olduğunu düşünüyorsanız, <strong><a href="mailto:destek@tvgfbf.gov.tr">destek@tvgfbf.gov.tr</a></strong> adresinden bizimle iletişime geçebilirsiniz.</p>
          <button onClick={handleOk}>Anladım 👋🏻</button>
        </div>
      </Modal>
    </div>
  );
}

export default HomePage;
