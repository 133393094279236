import React, { useEffect, useState } from 'react';
import "../assets/style/VisaPage.css";
import axios from 'axios';
import Button from '../components/Button/Button';
import { Link,useParams } from 'react-router-dom';


export default function VisaPage() {
  const { tc } = useParams();
  const token = "09eb401e8b72dc68a4fca80872896456157d828d";
  const apiUrl = `https://sbs.tvgfbf.gov.tr/api/coach/?tc=${tc}`;
  const [userData,setUserData] = useState(null)
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setUserData(response?.data?.results)
        setLoading(false);
      } catch (error) {
        console.error("API isteği sırasında hata oluştu:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl,tc]);

  if (loading) {
    return <div className="loading-screen">;Yükleniyor...</div>; // Yükleme ekranını burada göster
  }

  return (
    <div className='visa-page-section'>
      <p className='personal-information'>Kişisel Bilgiler</p>
      <div className="visa-page-form">
      <div className='visa-user-info'>
          <p>isim</p>
          <p className='info-card-text'>{userData?.person__user__first_name}</p>
        </div>       
         <div className='visa-user-info'>
          <p>Soyismi</p>
          <p className='info-card-text'>{userData?.person__user__last_name}</p>
        </div>       
         <div className='visa-user-info'>
          <p>T.C Kimlik Numarası</p>
          <p className='info-card-text'>{userData?.person__tc}</p>
        </div>       
         <div className='visa-user-info'>
          <p>Telefon Numarası</p>
          <p className='info-card-text'>{userData?.communication__phoneNumber}</p>
        </div>
      </div>
      <p className='visa-information'>Vize Bilgileri</p>
      <div className='visa-info-section'>
        <div className='visa-section'>
          <p>Vize Durumu</p>
        <div className='visa-status' style={{
          backgroundColor:userData?.visa__isActive === true ? "#009B10" : "#D40000"
        }}>
         <p>{userData?.visa__isActive === true ? "Aktif" : "Pasif"}</p> 
        </div>
        </div>
        <div style={{display:userData?.visa__isActive === true ? "block" : "none"}} className='visa-finish-card'>
        <p>vize bitiş tarihi</p>
        <div  className='visa-finish-date'>
          <p>{userData?.visa__expireDate }</p>
        </div>
        </div>
      </div>


      {/* vize durumu pasif ise görünücek ekran */}
      <div style={{
        display:userData?.visa__isActive === true ? "none" : "flex"
      }} className='visa-passif-section'>
        <p className='warning-text'>Vize Ödemesi Gerçekleştirmeniz Gerekmektedir.</p>
        <p className='visa-pay-info'>Vize Ödemesi Yap butonunu kullanarak, vize ücretlerinizi online olarak ödeyebilirsiniz.</p>
        <Link style={{width:"90%", display:"flex",justifyContent:"center"}} to={"https://tvgfbf.gov.tr/vize-odemeleri-kredi-karti-36"}>
        <Button text={"Vize Ödemesi Yap"} backgroundColor={"black"} width={"90%"}/>
        </Link>
        
      </div>
    </div>
  )
}
