import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
  return (
    <div className='error-page'>
        <p>Aradığınız sayfaya Ulaşılmadı</p>
        <Link to={"/"}>
         <p>Anasayfaya dön</p>
        </Link>
    </div>
  )
}
