import React, { useState } from 'react';
import './Button.css';

export default function Button({
  onClick,
  text,
  backgroundColor,
  color,
  width,
  height,
  hoverColor,
  hoverBackColor,
  borderRadius,
  display,
  img,
  boxShadow,
  fontWeight,
  type,
  padding
 }) {

  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    display: display || "block",
    borderRadius: borderRadius || "5px",
    backgroundColor: backgroundColor || "#2EB698",
    width: width || "100%",
    fontWeight: fontWeight||"",
    color: color || "white",
    border: isHovered ? (`1px solid ${hoverColor}`) : "1px solid transparent",
    height: height || "100%",
    transition: "background-color 0.3s ease" ,
    boxShadow: boxShadow || "" , 
    padding : padding || "15px",
    opacity : isHovered ? 0.8 : ""
  };
  return (
    <button
    type={type}
      className="CustomButton"
      style={buttonStyle}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {img ? <img src={img} /> : ""}
     <p style={{margin:"0",fontSize:"15px"}}>{text}</p> 

    </button>
  );
}