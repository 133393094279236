import React from "react";
import logo from "../logo.svg"
export default function NavBar() {
  return (
    <>
      <img className="App-logo" src={logo} alt="logo" style={{marginTop:"0.5rem"}}/>
      <div className="head">
        <p>Türkiye Vücut Geliştirme Ve Bilek Güreşi Federasyonu</p>
        <p>
          <strong>Antrenör Vize Sorgulama Sistemi</strong>
        </p>
      </div>
    </>
  );
}
