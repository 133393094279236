import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/HomePage";
import VisaPage from "../pages/VisaPage";
import Error from "../pages/Error";

const routes = createBrowserRouter([
    {
        path:"/",
        element:<HomePage/>
    },    
    {
        path:"/visa/:tc",
        element:<VisaPage/>
    },
    {
        path:"*",
        element:<Error/>
    },

])

export default routes