import React from "react";
import "./Input.css";

export default function Input({
  placeholder,
  type,
  color,
  description,
  value, 
  onChange,
  margin,
  width,
  inputWidth,
  padding,
}) {
  const containerstyle = {
    margin: margin || "0",
    width: width || "100%",
  };
  const style = {
    backgroundColor: color || "#EEE",
    borderRadius: "5px",
    padding: padding ||"7px",
    width: inputWidth || "100%",
    border: "none",
    fontSize: "12px",
  };


  return (
    <div className="custom-input-container" style={containerstyle}>
      <p>{description}</p>
      <input
        required
        style={style}
        type={type}
        placeholder={placeholder}
        value={value} 
        onChange={onChange}
      />
    </div>
  );
}